import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { ActivityCard, ActivityDetails, ActivityViewedEventProps, User, UserTrackableTraits } from '@/types'

type OpenGraphType = {
  siteName: string
  description: string
  templateTitle?: string
  logo?: string
}
// !STARTERCONF This OG is generated from https://github.com/theodorusclarence/og
// Please clone them and self-host if your site is going to be visited by many people.
// Then change the url and the default logo.
export function openGraph({
  siteName,
  templateTitle,
  description,
  // !STARTERCONF Or, you can use my server with your own logo.
  logo = 'https://og.<your-domain>/images/logo.jpg',
}: OpenGraphType): string {
  const ogLogo = encodeURIComponent(logo)
  const ogSiteName = encodeURIComponent(siteName.trim())
  const ogTemplateTitle = templateTitle ? encodeURIComponent(templateTitle.trim()) : undefined
  const ogDesc = encodeURIComponent(description.trim())

  return `https://og.<your-domain>/api/general?siteName=${ogSiteName}&description=${ogDesc}&logo=${ogLogo}${
    ogTemplateTitle ? `&templateTitle=${ogTemplateTitle}` : ''
  }`
}

export function getFromLocalStorage(key: string): string | null {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(key)
  }
  return null
}

export function getFromSessionStorage(key: string): string | null {
  if (typeof sessionStorage !== 'undefined') {
    return sessionStorage.getItem(key)
  }
  return null
}

export function getMobileOperatingSystem() {
  const userAgent =
    typeof window !== 'undefined' ? navigator.userAgent || navigator.vendor || window.opera : 'undefined'
  if (/windows phone/i.test(userAgent)) {
    return 'https://play.google.com/store/apps/details?id=com.vermutapp.mobile'
  }

  if (/android/i.test(userAgent)) {
    return 'https://play.google.com/store/apps/details?id=com.vermutapp.mobile'
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    alert('https://apps.apple.com/us/app/vermut/id1591939009')
    return 'https://apps.apple.com/us/app/vermut/id1591939009'
  }

  return 'https://play.google.com/store/apps/details?id=com.vermutapp.mobile'
}

export function delay(interval: number) {
  return new Promise((resolve) => setTimeout(resolve, interval))
}

const extract = (evt: string, data) => {
  let properties = {}

  const { userInfo, activity, ...rest } = data

  if (evt === 'login' || evt === 'identify') {
    properties = getUserTraits(userInfo)
  }

  if (activity) {
    properties = { ...properties, ...getActivityProps(evt, activity) }
  }

  if (rest) {
    properties = { ...properties, ...rest }
  }

  return properties
}

export const getActivityProps = (evt: string, activity: ActivityCard | ActivityDetails): ActivityViewedEventProps => {
  const categories = (activity?.categories || []).map((cat) => cat.name)
  const activityProps = {
    activityId: `${activity.id}`,
    name: activity.name,
    categories,
    price: activity.cost * 100,
    currency: activity.costType === 'credit' ? 'credit' : 'EUR',
    partner: activity?.creator?.name || undefined,
    url: activity?.detailsUrl || '',
    // imageUrl: activity.logoUrl,
    // position: activity.position,
    bookedByFriends: activity['isBookedByFollowee'] || false,
  }

  if (!_isEmpty(activity)) {
    // activityProps =
    // if ((activity.funnel_step === '2' && evt == 'activity_summary') || evt === 'Booking Summary Viewed') {
    //   // Booking Summary Viewed
    //   const sessionTime = activity.sessionTime.split('To')
    //   activityProps['activity_date'] = activity.sessionDate
    //   activityProps['activity_start_time'] = sessionTime[0] || ''
    //   activityProps['activity_end_time'] = sessionTime[1] || ''
    // }
    // if ((activity.funnel_step === '3' && evt == 'purchase') || evt === 'Booking Completed') {
    //   // Booking Completed
    //   const sessionTime = activity.sessionTime.split('To')
    //   activityProps['value'] = activity.cost * activity.totalParticipants
    //   activityProps['activity_date'] = activity.sessionDate
    //   activityProps['activity_start_time'] = sessionTime[0] || ''
    //   activityProps['activity_end_time'] = sessionTime[1] || ''
    //   activityProps['payment_method'] = activity.paymentMethod
    //   activityProps['quantity'] = activity.totalParticipants
    // }
  }

  return activityProps
}

export const getUserTraits = (userInfo: User): UserTrackableTraits => {
  const userTraits = {
    id: userInfo.id,
    firstname: userInfo.firstname,
    lastname: userInfo.lastname,
    email: userInfo.email,
    phone: userInfo.phone,
    street: '',
    city: userInfo?.city ? userInfo?.city : '',
    state: userInfo?.state ? userInfo?.state : '',
    country: userInfo?.country ? userInfo?.country : '',
    createdAt: userInfo?.joiningDate ? userInfo?.joiningDate : '',
    joiningDate: userInfo?.joiningDate ? userInfo?.joiningDate : '',
    isSubscriber: userInfo.meta?.subscriptionStatus === 'trialing' ? false : userInfo.meta?.isSubscribed,
    subscriptionPlanName: `${userInfo.meta?.subscriptionType}`,
    isTrialling: userInfo.meta?.subscriptionStatus === 'trialing',
  }

  return userTraits
}

export function activityLanguageLabelFor(langKey, lang) {
  const activityLanguages = {
    es: { es: 'Español', en: 'Inglés', ca: 'Catalán' },
    en: { es: 'Spanish', en: 'English', ca: 'Catalan' },
  }

  return _get(activityLanguages, `${lang}.${langKey}`, langKey) || langKey
}
