'use client'
import Link, { LinkProps } from 'next/link'
import React, { useContext } from 'react'

import { useTracking } from '@/hooks/useTracking'

import { PageProgressContext } from '@/providers/PageProgressContext'

import { TrackableEvent } from '@/types'

export type WebLinkProps = {
  href: string
  children: React.ReactNode
  // openNewTab?: boolean
  disabled?: boolean
  className?: string
  nextLinkProps?: Omit<LinkProps, 'href'>
  events?: TrackableEvent[]
} & React.ComponentPropsWithRef<'a'>

function WebLink({ href, children, disabled = false, className, nextLinkProps, events }: WebLinkProps) {
  const { progressBar } = useContext(PageProgressContext)

  const tracking = useTracking()

  const handleClickCapture = () => {
    progressBar.start()

    if (events && events.length > 0) {
      for (const evt of events) {
        tracking.track(evt)
      }
    }
  }

  const handledDisalbed = () => {}

  if (disabled) {
    return (
      <a onClick={handledDisalbed} className={className} {...nextLinkProps}>
        {children}
      </a>
    )
  }

  return (
    <Link href={href} className={className} {...nextLinkProps} onClickCapture={handleClickCapture}>
      {children}
    </Link>
  )
}

export default WebLink
