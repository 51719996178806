import * as React from 'react'
import { IconType } from 'react-icons'
import { ImSpinner2 } from 'react-icons/im'

import clsxm from '@/lib/clsxm'

const ButtonVariant = ['default', 'primary', 'hangout', 'share', 'white'] as const
const ButtonSize = ['sm', 'md', 'base'] as const

type ButtonProps = {
  isLoading?: boolean
  isDarkBg?: boolean
  variant?: (typeof ButtonVariant)[number]
  size?: (typeof ButtonSize)[number]
  leftIcon?: IconType
  rightIcon?: IconType
  leftIconClassName?: string
  rightIconClassName?: string
} & React.ComponentPropsWithRef<'button'>

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled: buttonDisabled,
      isLoading,
      variant = 'primary',
      size = 'base',
      isDarkBg = false,
      leftIcon: LeftIcon,
      rightIcon: RightIcon,
      leftIconClassName,
      rightIconClassName,
      ...rest
    },
    ref,
  ) => {
    const disabled = isLoading || buttonDisabled

    return (
      <button
        ref={ref}
        type="button"
        disabled={disabled}
        className={clsxm(
          'inline-flex items-center rounded font-medium',
          'focus-visible:ring-primary-500 focus:outline-none focus-visible:ring',
          'shadow-sm',
          'transition-colors duration-75',
          //#region  //*=========== Size ===========
          [
            size === 'base' && ['px-8 py-4', 'text-2xl', 'rounded-2xl'],
            size === 'md' && ['px-[14px] py-[10px]', 'text-[20px]', 'rounded-[10px]'],
            size === 'sm' && ['px-[14px] py-[10px]', 'text-base md:text-sm', 'rounded-[10px]'],
          ],
          //#endregion  //*======== Size ===========
          //#region  //*=========== Variants ===========
          [
            variant === 'default' && [
              'text-text-800',
              'border-text-500 border',
              // 'hover:bg-primary-50 active:bg-primary-100 disabled:bg-primary-100',
            ],
            variant === 'primary' && [
              'bg-primary-800 bg-gradient-to-b  from-[#FD3A5A] font-semibold text-white shadow-lg transition-colors duration-300',
              'hover:bg-primary-900 hover:text-white',
              'active:bg-primary-900',
            ],
            variant === 'hangout' && ['bg-tertiaryYellow-400', 'text-text-800'],
            variant === 'share' && ['bg-secondary-50', 'text-text-800'],
            variant === 'white' && ['bg-white shadow-lg', 'text-primary-700'],
          ],
          //#endregion  //*======== Variants ===========
          'disabled:cursor-not-allowed',
          'disabled:opacity-50',
          isLoading && 'relative text-transparent transition-none hover:text-transparent disabled:cursor-wait',
          className,
        )}
        {...rest}>
        {isLoading && (
          <div
            className={clsxm('absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2', {
              'text-white': ['primary', 'dark'].includes(variant),
              'text-black': ['light'].includes(variant),
              'text-primary-500': ['outline', 'ghost'].includes(variant),
            })}>
            <ImSpinner2 className="animate-spin" />
          </div>
        )}
        {LeftIcon && (
          <div className={clsxm([size === 'base' && 'mr-1', size === 'sm' && 'mr-1.5'])}>
            <LeftIcon
              className={clsxm(
                [size === 'base' && 'md:text-md text-md', size === 'sm' && 'md:text-md text-sm'],
                leftIconClassName,
              )}
            />
          </div>
        )}
        {children}
        {RightIcon && (
          <div className={clsxm([size === 'base' && 'ml-1', size === 'sm' && 'ml-1.5'])}>
            <RightIcon
              className={clsxm(
                [size === 'base' && 'text-md md:text-md', size === 'sm' && 'md:text-md text-sm'],
                rightIconClassName,
              )}
            />
          </div>
        )}
      </button>
    )
  },
)

export default Button
